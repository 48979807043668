jQuery('.client-slider').slick({
	infinite: true,
	slidesToShow: 6,
	slidesToScroll: 1,
	dots: false,
	arrows: false,
	autoplay: true,
	autoplaySpeed: 3000,
	pauseOnHover: false,
	pauseOnFocus: false,
	responsive: [
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 6,
			}
		},
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 4,
			}
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 4,
				slidesToScroll: 2,
				infinite: true,
				dots: true
			}
		}
	]
});

jQuery('.testimonials_slider').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: true,
	arrows: false,
	autoplay: true,
	autoplaySpeed: 4500,
	pauseOnHover: true,
	pauseOnFocus: false,
});
