var maxServices = 0;

jQuery('.single_service').each(function() {
	if(jQuery(this).outerHeight(true) > maxServices) {
		maxServices = jQuery(this).outerHeight(true)
	}
});

jQuery('.single_service').height(maxServices);

jQuery(document).ready(function () {
	var acc = document.getElementsByClassName("mobile-dropdown");
	var i;
	for (i = 0; i < acc.length; i++) {
		acc[i].addEventListener("click", function() {
			this.classList.toggle("opened");
			var panel = this.nextElementSibling;
			if (panel.style.maxHeight) {
				panel.style.maxHeight = null;
				panel.classList.toggle("opened");
			} else {
				panel.style.maxHeight = panel.scrollHeight + "px";
				panel.classList.toggle("opened");
			}
		});
	}
});

if( jQuery(".toggle .toggle_title").hasClass('active') ){
	jQuery(".toggle .toggle_title.active").closest('.toggle').find('.toggle_inner').show();
}

jQuery(".toggle .toggle_title").click(function() {
	if( jQuery(this).hasClass('active') ){
		jQuery(this).removeClass("active").closest('.toggle').find('.toggle_inner').slideUp(300);
	} else {
		jQuery(".toggle .toggle_title.active").closest('.toggle').find('.toggle_inner').slideUp(300);
		jQuery(".toggle .toggle_title.active").removeClass('active');
		jQuery(this).addClass("active").closest('.toggle').find('.toggle_inner').slideDown(300);
	}
});
