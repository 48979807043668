document.addEventListener("DOMContentLoaded", function () {
	
	const slider_swiper = new Swiper(".slider_swiper", {
		slidesPerView: 1,
		grabCursor: true,
		loop: true,
		speed: 1000,
		//navigation: {
		//	enabled: true,
		//	nextEl: '.swiper-button-next',
		//	prevEl: '.swiper-button-prev',
		//},
		

		autoplay: {
			delay: 3000,
		},
	});

});