jQuery('.woocommerce-accordion').accordion({
	heightStyle: "content",
	collapsible: true,
});

jQuery(document).ready(function() {
	jQuery('select.orderby').selectize();
	jQuery('body').on('click', '.stars  a', function( event ) {
		event.preventDefault();
		var selected = jQuery.grep(this.className.split(" "), function(v, i){
			return v.indexOf('star') === 0;
		}).join();
		selected = selected.replace('star-', '');

		jQuery('.stars a').each(function() {
			var star_rating = jQuery.grep(this.className.split(" "), function(v, i){
				return v.indexOf('star') === 0;
			}).join();
			star_rating = star_rating.replace('star-', '');
			var element = '.star-' + star_rating;
			if(star_rating <= selected) {
				jQuery(element).addClass('active');
			} else {
				jQuery(this).removeClass('active');
			}
		});
	});


	jQuery('#password').each(function() {
		jQuery(this).after('<input type="button" value="Show" id="show_hide_password" class="show_hide_password"></input>');
	});
	jQuery('#reg_password').each(function() {
		jQuery(this).after('<input type="button" value="Show" id="show_hide_password_reg" class="show_hide_password"></input>');
	});

	jQuery('#show_hide_password').click(function() {
		switch_input_type('password');
		switch_label('show_hide_password');
	});
	jQuery('#show_hide_password_reg').click(function() {
		switch_input_type('reg_password');
		switch_label('show_hide_password_reg');
	});

	function switch_input_type( input_box ) {
		var x = document.getElementById(input_box);
		if (x.type === "password") {
			x.type = "text";
		} else {
			x.type = "password";
		}
	}
	function switch_label( input_box ) {
		var x = document.getElementById(input_box);


		if (x.value === "Show") {
			x.value = "Hide";
		} else {
			x.value = "Show";
		}
	}
});
