jQuery( window ).resize(function() {
	var fixed_area = jQuery('.fixed').outerHeight(true) ;
	jQuery( ".fixed_start" ).height(fixed_area);
});

jQuery( document ).ready(function(){
	jQuery('.fixed').addClass('affix');
	var fixed_area = jQuery('.fixed').outerHeight(true) ;
	jQuery('.fixed_start').height(fixed_area);
});
