// to top right away
if ( window.location.hash ) scroll(0,0);
// void some browsers issue
setTimeout( function() { scroll(0,0); }, 1);

jQuery(function() {
    // your current click function
	jQuery('a[href*=#]:not([href=#])').click(function() {
		if(!jQuery(this).closest('ul').hasClass('wc-tabs')) {
			// if(!jQuery(this).hasClass('tab_nav')) {
			if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') || location.hostname == this.hostname) {
				var top_value = jQuery(".fixed").outerHeight(true) + jQuery(".breadcrumbs").outerHeight(true) + 50;
				var target = jQuery(this.hash);
				target = target.length ? target : jQuery('[name=' + this.hash.slice(1) + ']');
				if (target.length) {
					jQuery('html, body').animate({
						scrollTop: target.offset().top - top_value
					}, 1000);
					return false;
				}
			}
		}
	});
    // *only* if we have anchor on the url
    if(window.location.hash) {
        // smooth scroll to the anchor id
		var top_value = jQuery(".fixed").outerHeight(true) + jQuery("#wpadminbar").outerHeight(true);
		var target = jQuery(window.location.hash);
		target = target.length ? target : jQuery('[name=' + window.location.hash.slice(1) + ']');

        jQuery('html, body').animate({
			scrollTop: target.offset().top - top_value
		}, 1000);
    }

});
